import { render, staticRenderFns } from "./examPaper.vue?vue&type=template&id=323dd93a&scoped=true&"
import script from "./examPaper.vue?vue&type=script&lang=js&"
export * from "./examPaper.vue?vue&type=script&lang=js&"
import style0 from "./examPaper.vue?vue&type=style&index=0&id=323dd93a&lang=less&scoped=true&"
import style1 from "./examPaper.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323dd93a",
  null
  
)

export default component.exports