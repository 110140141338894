<template>
  <div class="page-container examPaper-container">
    <el-card id="paper" class="box-card">
      <div slot="header" class="clearfix">
        <div class="exam-title">
          <div class="title text-center" v-if="asType!=='teacherView'">{{ examInfo.name }}</div>
          <div class="title text-center" v-else>{{ paperInfo.name }}</div>
          <div class="student-info flex flex-around" v-if="asType==='teacherEdit'">
            <span>学生账号: {{ studentAccount }}</span>
            <span>学生姓名: {{ studentName }}</span>
          </div>
          <div class="infos flex flex-around">
            <span v-if="asType!=='teacherView'">考核时间周期: {{ examInfo.startTime }}至{{ examInfo.endTime }}</span>
            <span v-if="asType==='studentView'||asType==='teacherEdit'">开始答卷时间: {{
                examResult.startTime
              }}</span>
            <span v-if="asType==='studentView'||asType==='teacherEdit'">交卷时间: {{
                examResult.endTime
              }}</span>
            <span v-if="paperInfo.type!=='single'">考卷总分: {{ paperInfo.totalScore }}</span>
            <span v-if="paperInfo.type!=='single'">考卷及格分: {{ paperInfo.passScore }}</span>
            <span v-if="asType==='studentView'||asType==='teacherEdit'">总得分: {{ examResult.score }}</span>
            <span
                v-if="asType==='studentView'||asType==='teacherEdit'">考核结果: {{
                enums.examResultStatus[examResult.status]
              }}</span>
          </div>
        </div>
      </div>
      <div class="box-content">
        <!--渲染试题列表-->
        <div id="questionSections" v-for="(section,sectionIndex) in questionSections">
          <!--单项选择题-->
          <div class="section-div" v-if="section.sectionType==='Radio'">
            <div class="section-title" v-if="paperInfo.type!=='single'">
              {{ enums.sectionChineseNumber[sectionIndex] }}、{{ section.sectionName }}
              <span
                  class="title-score-des">(每题{{ section['scorePerQuestion'] }}分,共{{
                  section['questionTotal']
                }}题,共计{{ section['sectionTotalScore'] }}分)</span>
              <!--              <span v-if="asType==='teacherEdit'||asType==='studentView'"-->
              <!--                    class="title-score-own">(得分:{{ sectionScore[sectionIndex] }}分)</span>-->
            </div>
            <div class="section-questions question-radio" v-for="(question,questionIndex) in section.sectionQuestions">
              <div class="question-title" v-if="question.contentType==='text'">
                <span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span>{{ question.content }}
              </div>
              <div class="question-title-html  flex flex-start" v-if="question.contentType==='html'">
                <span style="float:left;"><span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span></span>
                <div style="float:left" class="content-vhtml" v-html="question.content"></div>
              </div>
              <div class="options" v-if="asType==='exam'||asType==='studentView'||asType==='teacherEdit'">
                <el-radio-group v-model="studentAnswer.answerContent[question.id]"
                                :disabled="asType!=='exam'&&asType!=='teacherView'?true:false">
                  <el-radio class="block" v-for="option in question.options" :label="option.choice">
                    {{ option.choice }}、{{ option.content }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div class="options-show"
                   v-if="asType==='teacherView'">
                <div class="block" v-for="option in question.options">
                  {{ option.choice }}、{{ option.content }}
                  <div class="question-analysis per"
                       v-if="analysis&&questionAnalysis.hasOwnProperty(question.id)">
                    <div class="flex flex-start">
                      <span>选择次数：</span>
                      <span>{{ questionAnalysis[question.id][option.choice] }}次</span>
                    </div>
                    <div class="flex flex-start">
                      <span>选择比例：</span>
                      <el-progress
                          :percentage="((questionAnalysis[question.id]['percentage'+option.choice])*100).toFixed(0)"
                          style="width: 150px;" color="#13ce66"></el-progress>
                    </div>
                  </div>
                </div>
                <div class="question-analysis-this" v-if="analysis">
                  <el-tag type="info" size="medium" class="right-answer">标准答案:{{ question.standardAnswer }}
                  </el-tag>
                  <el-tag style="margin-left: 10px" v-if="questionAnalysis.hasOwnProperty(question.id)"
                          type="success" size="medium" class="right-answer">得分率:
                    {{ ((questionAnalysis[question.id]['scoreRate']) * 100).toFixed(0) }}%
                  </el-tag>
                </div>
              </div>
              <!--判断正确与否和展示答案-->
              <div class="answer" v-if="asType==='studentView'||asType==='teacherEdit'">
                <span class="right"
                      v-if="studentAnswer.answerContent[question.id]===question.standardAnswer"><i
                    class="el-icon-check"> 正确</i></span>
                <span class="wrong" v-else><i class="el-icon-close"> 错误</i></span>
                <el-tag type="info" size="medium" class="right-answer"
                        v-if="asType==='teacherEdit'||(asType==='studentView'&&examInfo.canViewAnswer)">
                  标准答案:{{ question.standardAnswer }}
                </el-tag>
              </div>
            </div>
          </div>
          <!--多项选择题-->
          <div class="section-div" v-if="section.sectionType==='Multiple'">
            <div class="section-title" v-if="paperInfo.type!=='single'">
              {{ enums.sectionChineseNumber[sectionIndex] }}、{{ section.sectionName }}
              <span
                  class="title-score-des">(每题{{ section['scorePerQuestion'] }}分,共{{
                  section['questionTotal']
                }}题,共计{{ section['sectionTotalScore'] }}分)</span>
              <!--              <span v-if="asType==='teacherEdit'||asType==='studentView'"-->
              <!--                    class="title-score-own">(得分:{{ sectionScore[sectionIndex] }}分)</span>-->
            </div>
            <div class="section-questions question-multiple"
                 v-for="(question,questionIndex) in section.sectionQuestions">
              <div class="question-title" v-if="question.contentType==='text'">
                <span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span>{{ question.content }}
              </div>
              <div class="question-title-html clearfix" v-if="question.contentType==='html'">
                <span style="float:left;"><span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span></span>
                <div style="float:left" class="content-vhtml" v-html="question.content"></div>
              </div>
              <!--渲染选项-->
              <div class="options" v-if="asType==='exam'||asType==='studentView'||asType==='teacherEdit'">
                <el-checkbox-group v-model="studentAnswer.answerContent[question.id]"
                                   :disabled="asType!=='exam'&&asType!=='teacherView'?true:false">
                  <el-checkbox class="block" v-for="option in question.options" :label="option.choice">
                    {{ option.choice }}、{{ option.content }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="options-show"
                   v-if="asType==='teacherView'">
                <div class="block" v-for="option in question.options">
                  {{ option.choice }}、{{ option.content }}
                  <div class="question-analysis per"
                       v-if="analysis&&questionAnalysis.hasOwnProperty(question.id)">
                    <div class="flex flex-start">
                      <span>选择次数：</span>
                      <span>{{ questionAnalysis[question.id][option.choice] }}次</span>
                    </div>
                    <div class="flex flex-start">
                      <span>选择比例：</span>
                      <el-progress
                          :percentage="((questionAnalysis[question.id]['percentage'+option.choice])*100).toFixed(0)"
                          style="width: 150px;" color="#13ce66"></el-progress>
                    </div>
                  </div>
                </div>
                <div class="question-analysis-this" v-if="analysis">
                  <el-tag type="info" size="medium" class="right-answer">
                    标准答案:{{ question.standardAnswers.toString() }}
                  </el-tag>
                  <el-tag style="margin-left: 10px" v-if="questionAnalysis.hasOwnProperty(question.id)"
                          type="success" size="medium" class="right-answer">得分率:
                    {{ ((questionAnalysis[question.id]['scoreRate']) * 100).toFixed(0) }}%
                  </el-tag>
                </div>
              </div>
              <!--判断正确与否和展示答案-->
              <div class="answer" v-if="asType==='studentView'||asType==='teacherEdit'">
                <span class="right"
                      v-if="isTwoArrHasSameItems(studentAnswer.answerContent[question.id],question.standardAnswers)"><i
                    class="el-icon-check"> 正确</i></span>
                <span class="wrong" v-else><i class="el-icon-close"> 错误</i></span>
                <el-tag type="info" class="right-answer"
                        v-if="asType==='teacherEdit'||(asType==='studentView'&&examInfo.canViewAnswer)">
                  标准答案:{{ question.standardAnswers.join("、") }}
                </el-tag>
              </div>
            </div>
          </div>
          <!--判断题-->
          <div class="section-div" v-if="section.sectionType==='Judge'">
            <div class="section-title" v-if="paperInfo.type!=='single'">
              {{ enums.sectionChineseNumber[sectionIndex] }}、{{ section.sectionName }}
              <span
                  class="title-score-des">(每题{{ section['scorePerQuestion'] }}分,共{{
                  section['questionTotal']
                }}题,共计{{ section['sectionTotalScore'] }}分)</span>
              <!--              <span v-if="asType==='teacherEdit'||asType==='studentView'"-->
              <!--                    class="title-score-own">(得分:{{ sectionScore[sectionIndex] }}分)</span>-->
            </div>
            <div class="section-questions question-judge" v-for="(question,questionIndex) in section.sectionQuestions">
              <div class="question-title" v-if="question.contentType==='text'">
                <span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span>{{ question.content }}
              </div>
              <div class="question-title-html clearfix" v-if="question.contentType==='html'">
                <span style="float:left;"><span v-if="paperInfo.type!=='single'">{{ questionIndex + 1 }}、</span></span>
                <div style="float:left" class="content-vhtml" v-html="question.content"></div>
              </div>
              <div class="options" v-if="asType==='exam'||asType==='studentView'||asType==='teacherEdit'">
                <el-radio-group v-model="studentAnswer.answerContent[question.id]"
                                :disabled="asType!=='exam'&&asType!=='teacherView'?true:false">
                  <el-radio class="block" label="true">
                    正确
                  </el-radio>
                  <el-radio class="block" label="false">
                    错误
                  </el-radio>
                </el-radio-group>
              </div>
              <div class="options-show flex flex-start" v-if="asType==='teacherView'&&!analysis">
                <div style="margin-left: 30px">正确</div>
                <div style="margin-left: 30px">错误</div>
              </div>
              <div class="options-show" v-if="asType==='teacherView'&&analysis">
                <div>正确</div>
                <div class="question-analysis per"
                     v-if="analysis&&questionAnalysis.hasOwnProperty(question.id)">
                  <div class="flex flex-start">
                    <span>选择次数：</span>
                    <span>{{ questionAnalysis[question.id]['true'] }}次</span>
                  </div>
                  <div class="flex flex-start">
                    <span>选择比例：</span>
                    <el-progress
                        :percentage="(questionAnalysis[question.id]['percentageTrue'])*100"
                        style="width: 150px;" color="#13ce66"></el-progress>
                  </div>
                </div>
                <div>错误</div>
                <div class="question-analysis per"
                     v-if="analysis&&questionAnalysis.hasOwnProperty(question.id)">
                  <div class="flex flex-start">
                    <span>选择次数：</span>
                    <span>{{ questionAnalysis[question.id]['false'] }}次</span>
                  </div>
                  <div class="flex flex-start">
                    <span>选择比例：</span>
                    <el-progress
                        :percentage="(questionAnalysis[question.id]['percentageFalse'])*100"
                        style="width: 150px;" color="#13ce66"></el-progress>
                  </div>
                </div>
                <div class="question-analysis-this" v-if="analysis">
                  <el-tag type="info" size="medium" class="right-answer">
                    标准答案:{{ enums.questionJudgeAnswer[question.standardAnswer] }}
                  </el-tag>
                  <el-tag style="margin-left: 10px" v-if="questionAnalysis.hasOwnProperty(question.id)"
                          type="success" size="medium" class="right-answer">得分率:
                    {{ ((questionAnalysis[question.id]['scoreRate']) * 100).toFixed(0) }}%
                  </el-tag>
                </div>
              </div>
              <!--判断正确与否和展示答案-->
              <div class="answer" v-if="asType==='studentView'||asType==='teacherEdit'">
                <span class="right"
                      v-if="studentAnswer.answerContent[question.id]===question.standardAnswer"><i
                    class="el-icon-check"> 正确</i></span>
                <span class="wrong" v-else><i class="el-icon-close"> 错误</i></span>
                <el-tag type="info" class="right-answer"
                        v-if="asType==='teacherEdit'||(asType==='studentView'&&examInfo.canViewAnswer)">
                  标准答案:{{ enums.questionJudgeAnswer[question.standardAnswer] }}
                </el-tag>
              </div>
            </div>
          </div>
        </div>
        <!--老师备注信息-->
        <div class="other-info" v-if="(asType==='teacherEdit'||asType==='studentView')&&false">
          <el-form>
            <el-form-item label="批注信息：">
              <el-input autosize type="textarea" :disabled="asType==='studentView'"
                        placeholder="对该答卷和学生的批注信息"
                        v-model="examResult.commentsAndNotes"
                        v-if="asType==='teacherEdit'||asType==='studentView'"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
    <!--底部按钮-->
    <div class="page-bottom-container" style="width: 100%;text-align: center;margin-top: 20px" v-if="false">
      <!--交卷按钮-->
      <el-button style="margin-left: 10px;" type="success" v-if="asType==='exam'"
                 icon="el-icon-success" @click="clickSendBtn(false)">交 卷
      </el-button>
      <!--保存批改按钮-->
      <el-button style="margin-left: 10px;" type="success" v-if="asType==='teacherEdit'"
                 icon="el-icon-success" @click="clickTeacherSaveBtn">保存批改
      </el-button>
      <!--打印按钮-->
      <el-button style="margin-left: 10px;" type="success" v-if="asType==='teacherView'"
                 icon="el-icon-printer" @click="clickPrintSaveBtn">打印网页考卷
      </el-button>
      <!--查看分析-->
      <el-button style="margin-left: 10px;" type="success" v-if="asType==='teacherView'&&examInfo.examStatus==='Over'"
                 icon="el-icon-s-data" @click="clickAnalysisBtn">{{ analysis ? '关闭作答分析' : '查看作答分析' }}
      </el-button>
      <!--导出按钮-->
      <el-button style="margin-left: 10px;" type="success" v-if="asType==='teacherView'"
                 icon="el-icon-reading" @click="clickExportWord">导出word
      </el-button>
    </div>
    <!--倒计时悬浮框-->
    <div class="left-time-box" v-if="asType==='exam'">
      <div style="color: #999;">当前时间</div>
      <div style="color:#999;">{{ now_time_text }}</div>
      <hr>
      <div>剩余时间</div>
      <div>{{ left_time_text }}</div>
    </div>
  </div>
</template>

<script>
import {msg_confirm, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {
  htmlAppend,
  isTwoArrHasSameItems,
  find_obj_from_arr_by_id,
  getQuery,
  objectArrToIdObject, arrRandom
} from '@/utils/common'
import {EnumsModel} from "@/model/EnumsModel";
import {QuestionPaperModel} from "@/model/exp/QuestionPaperModel";
import {QuestionResultModel} from "@/model/exp/QuestionResultModel";
import {QuestionExamModel} from "@/model/exp/QuestionExamModel";

export default {
  name: 'teacherExamPaper',
  watch: {
    // // 监听简答题教师设置分数
    // "examResult.blankQuestionScoreMap": {
    //   handler(o, n) {
    //
    //   }, deep: true
    // },
    // // 监听填空题教师设置分数
    // "examResult.completionQuestionScoreMap": {
    //   handler(o, n) {
    //
    //   }, deep: true
    // },
    // // 监听分析题师设置分数
    // "examResult.analysisQuestionScoreMap": {
    //   handler(o, n) {
    //
    //   }, deep: true
    // },
    // // 监听论述题教师设置分数
    // "examResult.essayQuestionScoreMap": {
    //   handler(o, n) {
    //
    //   }, deep: true
    // }
  },
  computed: {
    asType: function () {
      if (this.$route.query.hasOwnProperty('asType')) {
        return this.$route.query['asType']
      } else {
        return undefined
      }
    },
    examId: function () {
      if (this.$route.query.hasOwnProperty('examId')) {
        return Math.ceil(this.$route.query['examId'])
      } else {
        return undefined
      }
    },
    id: function () {
      if (this.$route.query.hasOwnProperty('id')) {
        return Math.ceil(this.$route.query['id'])
      } else {
        return undefined
      }
    },
    studentAccount: function () {
      if (this.$route.query.hasOwnProperty('studentAccount')) {
        return this.$route.query['studentAccount']
      } else {
        return undefined
      }
    },
    studentName: function () {
      if (this.$route.query.hasOwnProperty('studentName')) {
        return this.$route.query['studentName']
      } else {
        return undefined
      }
    },
  },
  data() {
    return {
      // 外部方法
      isTwoArrHasSameItems: isTwoArrHasSameItems,
      // 枚举
      enums: {
        sectionChineseNumber: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
        questionJudgeAnswer: EnumsModel.questionJudgeAnswer,
        examResultStatus: EnumsModel.examResultStatus
      },
      examInfo: {},
      examResult: {
        paper: {
          sections: []
        }
      },
      questionSections: [],
      paperInfo: {},
      // 学生选择和填写的答案列表
      studentAnswer: {
        answerContent: {}
      },
      // 连线题相关
      match: {},
      // 考核剩余时间倒计时
      left_time_timer: undefined,
      left_time: '',
      left_time_text: '00:00:00',
      now_time_text: '00:00:00',
      // 定时向后端保存试题答案
      timing_save_timer: undefined,
      timing_update_timer: undefined,
      // 计算各项分数
      sectionScore: [0, 0, 0, 0, 0, 0, 0, 0],
      // 试题分析数据按questionId标记
      analysis: false,
      questionAnalysis: {}
    }
  },
  mounted() {
    this.getExamInfo()
  },
  beforeDestroy() {
    this.TimeMethods().leavePage()
  },
  methods: {
    // 获取考核详情
    async getExamInfo() {
      let examResult = {}
      let examInfos = []
      // 根据asType类型筛选
      switch (this.asType) {
        case 'exam':// 学生考核状态
          [examInfos] = await ExamModel.getStudentExam(0, 0, {examId: this.examId})
          if (examInfos.length === 1) {
            this.examInfo = examInfos[0]
            // 判断考核状态，如果已停止则返回
            if (this.examInfo.examStatus !== 'Going') {
              this.$router.push('/exam/studentHistoryExam')
              return false
            }
            // 获取考核进行中的信息
            examResult = await ExamModel.startExam(this.examId)
            // 如果考卷已经有答案就渲染
            if (examResult.answerContent) {
              this.$set(this.studentAnswer, 'answerContent', examResult.answerContent)
            }
            // 遍历试题-设置缺失项
            for (let i = 0; i < examResult.paper.sections.length; i++) {
              // 设置多选题答案数组
              if (examResult.paper.sections[i].sectionType === 'Multiple') {
                examResult.paper.sections[i].sectionQuestions.forEach((li, index) => {
                  if (!this.studentAnswer.answerContent[li.question.id]) {// 没答案才设置
                    this.$set(this.studentAnswer.answerContent, examResult.paper.sections[i].sectionQuestions[index].question.id, [])
                  }
                })
              }
              // 设置填空题答案初始项
              if (examResult.paper.sections[i].sectionType === 'Completion') {
                examResult.paper.sections[i].sectionQuestions.forEach((li, index) => {
                  if (!this.studentAnswer.answerContent[li.question.id]) {// 没答案才设置
                    this.$set(this.studentAnswer.answerContent, examResult.paper.sections[i].sectionQuestions[index].question.id, [])
                  }
                })
              }
              // 设置联线题初始项
              if (examResult.paper.sections[i].sectionType === 'Match') {
                examResult.paper.sections[i].sectionQuestions.forEach(li => {
                  this.MatchMethods().setInitObj(li.question, i)
                  if (this.studentAnswer.answerContent[li.question.id]) {
                    this.$nextTick(() => {
                      this.MatchMethods().setLines(li.question)
                    })
                  }
                })
              }
            }
            // 大类和小类题目列表
            let questionSections = examResult.paper.sections
            // 判断是否需要小题随机排序
            if (examResult.paper.questionRandom) {
              let questionSectionsNew = []
              questionSections.forEach(li => {
                let question = arrRandom(li.sectionQuestions)
                questionSectionsNew.push(question)
              })
              questionSections = questionSectionsNew
            }
            this.questionSections = questionSections
            this.studentAnswer.examId = this.examId
            this.studentAnswer.paperId = examResult.paper.id
            this.studentAnswer.subId = examResult.paper.subId

            // 开始考核倒计时
            this.TimeMethods().secondCount()
            // 开始定时保存
            this.TimeMethods().timingSaveAnswer()
            // 开始定时刷新开始信息，关注延时时间变化
            this.TimeMethods().timingUpdateExamInfo()
          } else {
            msg_err('未找到该考核信息')
          }
          // 关联
          this.examResult = examResult
          break
        case 'studentView':// 学生查看答卷状态
          examResult = await ExamModel.findOverExamById(this.id)
          this.studentAnswer.answerContent = examResult.answerContent
          // 遍历试题-设置缺失项
          for (let i = 0; i < examResult.paper.sections.length; i++) {
            // 设置填空题答案初始项
            if (examResult.paper.sections[i].sectionType === 'Completion') {
              examResult.paper.sections[i].sectionQuestions.forEach((li, index) => {
                li.question['answerSize'] = li.question['answerList'].length
                if (!this.studentAnswer.answerContent[li.question.id]) {// 没答案才设置
                  this.$set(this.studentAnswer.answerContent, examResult.paper.sections[i].sectionQuestions[index].question.id, [])
                }
              })
            }
            // 设置联线题初始项
            if (examResult.paper.sections[i].sectionType === 'Match') {
              examResult.paper.sections[i].sectionQuestions.forEach(li => {
                this.MatchMethods().setInitObj(li.question, i)
                if (this.studentAnswer.answerContent[li.question.id]) {
                  this.$nextTick(() => {
                    this.MatchMethods().setLines(li.question)
                  })
                }
              })
            }
          }
          this.examInfo = examResult.exam
          this.questionSections = examResult.paper.sections
          // 设置几个主观题分数设置项
          if (!examResult.blankQuestionScoreMap) {
            examResult.blankQuestionScoreMap = {}
          }
          if (!examResult.completionQuestionScoreMap) {
            examResult.completionQuestionScoreMap = {}
          }
          if (!examResult.essayQuestionScoreMap) {
            examResult.essayQuestionScoreMap = {}
          }
          if (!examResult.analysisQuestionScoreMap) {
            examResult.analysisQuestionScoreMap = {}
          }
          // 关联
          this.examResult = examResult
          // 计算各项总分
          this.calSectionScore()
          break
        case 'teacherEdit':// 教师批改答卷状态
          let result = await QuestionResultModel.getOne(this.id);// 这儿传的是答卷id
          let exam = await QuestionExamModel.getOne(result.examId);
          let paper = await QuestionPaperModel.getOne(exam.paperId);
          paper.sections = JSON.parse(paper.sectionInfos);
          this.studentAnswer.answerContent = JSON.parse(result.objContent)
          // 遍历试题-设置缺失项
          for (let i = 0; i < paper.sections.length; i++) {

          }
          this.examInfo = exam
          this.questionSections = JSON.parse(paper.sectionInfos)
          // 关联
          this.examResult = result
          this.paperInfo = paper;
          // 计算各项总分
          this.calSectionScore()
          break
        case 'teacherView':// 教师预览考卷状态
          let data = await QuestionPaperModel.getOne(this.id)// 这儿传的是考卷id
          if (data) {
            data.sections = JSON.parse(data.sectionInfos)
            let paper = data
            this.examResult = paper
            this.paperInfo = JSON.parse(JSON.stringify(paper))
            this.questionSections = paper.sections
          } else {
            msg_err('未找到该考卷信息')
          }
          break
      }
    },
    // 点击交卷按钮
    async clickSendBtn(direct) {
      if (!direct) {
        if (await msg_confirm('确定要交卷吗？交卷后不能再次答题！')) {
          let data = await ExamModel.endExam(this.studentAnswer)
          if (data) {// 交卷成功
            msg_success('提交考卷成功')
            this.$router.push('/exam/studentHistoryExam')
          } else {// 交卷失败

          }
        }
      } else {
        let data = await ExamModel.endExam(this.studentAnswer)
        if (data) {// 交卷成功
          msg_success('提交考卷成功')
          this.$router.push('/exam/studentHistoryExam')
        } else {// 交卷失败

        }
      }
    },
    // 教师-保存答卷信息
    async clickTeacherSaveBtn() {
      if (await msg_confirm('确定要保存答卷修改信息吗？')) {
        // 计算简答题总分
        let data = await ExamModel.updateExamResult(this.examResult)
        if (data) {
          msg_success('保存答卷信息成功')
          this.getExamInfo()
        }
      }

    },
    // 点击打印考卷按钮
    clickPrintSaveBtn() {
      let headstr = '<html><head><title></title></head><body>'
      let footstr = '</body>'
      let newstr = document.getElementById('paper').innerHTML
      let oldstr = document.body.innerHTML
      document.body.innerHTML = headstr + newstr + footstr
      window.print()
      document.body.innerHTML = oldstr
      msg_success('刷新页面后才能正常使用其他功能！')
      return false
    },
    // 导出word考卷
    clickExportWord() {
      PaperModel.exportOnePaper(this.examResult.id, this.paper.name)
    },
    // 连线题Methods
    MatchMethods() {
      let $this = this
      return {
        // 设置初始对象
        setInitObj(question, sectionIndex) {
          $this.$set($this.match, question.id, {
            clickLeftIndex: undefined,
            clickRightIndex: undefined,
            sectionIndex: sectionIndex,// 保存在questionSections中的index
            svgList: {},
            leftOptions: question.leftOptions,
            rightOptions: question.rightOptions
          })
        },
        // 点击左边选项
        clickLeftOption(id, index, question) {
          $this.$set($this.match[id], 'clickLeftIndex', index)
          $this.$set($this.match[id], 'leftOptions', question.leftOptions)
          $this.$set($this.match[id], 'rightOptions', question.rightOptions)
        },
        // 点击右边选项
        clickRightOption(id, index) {
          $this.$set($this.match[id], 'clickRightIndex', index)
          if ($this.asType === 'exam') {
            this.reDrawLine(id)
          }
        },
        // 判断是否需要重新划线
        reDrawLine(id) {
          if ($this.match[id].clickLeftIndex !== undefined && $this.match[id].clickRightIndex !== undefined) {
            // 如果已经存在连线则删除
            for (let i in $this.match[id].svgList) {
              if ($this.match[id].svgList.hasOwnProperty(i)) {
                if ($this.match[id].svgList[i][0] === $this.match[id].clickLeftIndex) {
                  document.querySelector('#' + i).remove()
                  $this.match[id].svgList[i] = [undefined, undefined]
                }
                if ($this.match[id].svgList[i][1] === $this.match[id].clickRightIndex) {
                  document.querySelector('#' + i).remove()
                  $this.match[id].svgList[i] = [undefined, undefined]
                }
              }
            }
            // 再次遍历删除undefined
            for (let i in $this.match[id].svgList) {
              if ($this.match[id].svgList.hasOwnProperty(i)) {
                if ($this.match[id].svgList[i][0] === undefined) {
                  delete $this.match[id].svgList[i]
                }
              }
            }
            // 新划线
            this.drawLine(id, $this.match[id].clickLeftIndex, $this.match[id].clickRightIndex)
          }
        },
        // 画左右两边的连接线
        drawLine(id, leftIndex, rightIndex) {
          leftIndex += 1
          rightIndex += 1
          /**
           * y起始点57，每下一个y增加50
           * x不会变，只会变y
           *
           */
          let x_start = 150
          let x_end = 250
          let y_start = 21
          let y_end = 21
          y_start = 21 + (leftIndex - 1) * 50
          y_end = 21 + (rightIndex - 1) * 50
          let svgId = 'svg' + Math.ceil(Math.random() * 10000).toString()
          // 存入对象
          $this.$set($this.match[id].svgList, svgId, [leftIndex - 1, rightIndex - 1])
          let svg = '<svg id="' + svgId + '" style="position:absolute;width: 400px;height: 370px;"><line x1="' + x_start + '" y1="' + y_start + '" x2="' + x_end + '" y2="' + y_end + '" style="stroke: red; stroke-width: 2;"></line><svg>'
          htmlAppend(document.querySelector('.lines-' + id), svg)
          this.setMapAnswer(id)
        },
        // 删除所有连线
        deleteAllLine(id) {
          // 删除所有连线
          for (let i in $this.match[id].svgList) {
            if ($this.match[id].svgList.hasOwnProperty(i)) {
              document.querySelector('#' + i).remove()
              $this.match[id].svgList[i] = [undefined, undefined]
            }
          }
          // 再次遍历删除undefined
          for (let i in $this.match[id].svgList) {
            if ($this.match[id].svgList.hasOwnProperty(i)) {
              if ($this.match[id].svgList[i][0] === undefined) {
                delete $this.match[id].svgList[i]
              }
            }
          }
          // 删除左右选择
          $this.match[id].svgList = {}
          $this.match[id].clickLeftIndex = undefined
          $this.match[id].clickRightIndex = undefined
        },
        // 生成对应的mapAnswer
        setMapAnswer(id) {
          let mapAnswer = {}
          let leftOptions = $this.match[id].leftOptions
          let rightOptions = $this.match[id].rightOptions
          for (let i in $this.match[id].svgList) {
            if ($this.match[id].svgList.hasOwnProperty(i)) {
              let left = $this.match[id].svgList[i][0]
              let right = $this.match[id].svgList[i][1]
              mapAnswer[leftOptions[left]] = rightOptions[right]
            }
          }
          $this.studentAnswer.answerContent[id] = mapAnswer
        },
        // 学生预览和教师批注时自动连线
        setLines(question) {
          let mapAnswer = $this.studentAnswer.answerContent[question.id]
          // 绘制连线
          Object.keys(mapAnswer).forEach(li => {
            let leftIndex = question.leftOptions.indexOf(li)
            let rightIndex = question.rightOptions.indexOf(mapAnswer[li])
            setTimeout(() => {
              this.drawLine(question.id, leftIndex, rightIndex)
            }, 1500)
          })
        }
      }
    },
    // 计时Methods
    TimeMethods() {
      let $this = this
      return {
        // 开始倒计时
        secondCount() {
          let startTime = new Date()
          let endTime = new Date($this.examInfo.endTime)
          $this.left_time = endTime - startTime
          $this.left_time_timer = setInterval(() => {
            $this.left_time = $this.left_time - 1000
            let {bool, hours = '00', minutes = '00', seconds = '00'} = this.countdown($this.left_time)
            if (bool) { // 结束倒计时
              msg_err('时间结束，将自动提交考卷，请勿刷新页面！')
              this.leavePage()
              $this.clickSendBtn(true)
            }
            $this.left_time_text = hours + ':' + minutes + ':' + seconds
            $this.now_time_text = date_format(new Date().getTime(), 'HH:mm:ss')
          }, 1000)
        },
        countdown(leftTime) {
          let bool = false
          if (leftTime <= 0) {
            bool = true
            return {bool}
          }
          let hours = parseInt(leftTime / 1000 / 60 / 60 % 24, 10)
          if (hours < 10) {
            hours = '0' + hours
          }
          let minutes = parseInt(leftTime / 1000 / 60 % 60, 10)
          if (minutes < 10) {
            minutes = '0' + minutes
          }
          let seconds = parseInt(leftTime / 1000 % 60, 10)
          if (seconds < 10) {
            seconds = '0' + seconds
          }
          return {bool, hours, minutes, seconds}
        },
        // 定时保存已有的答案
        timingSaveAnswer() {
          $this.timing_save_timer = setInterval(async () => {
            let data = await ExamModel.updateExamAnswerInTime({
              examId: $this.examId,
              answerContent: $this.studentAnswer.answerContent
            })
            if (!data) {
              // 保存失败
            } else {
              // 保存成功
            }
          }, 10000)// 每10秒保存一次
        },
        // 学生考核时-定时刷新考核信息，检测是否有考核延时
        timingUpdateExamInfo() {
          $this.timing_update_timer = setInterval(async () => {
            let [examInfos] = await ExamModel.getStudentExam(0, 0, {examId: $this.examInfo.id})
            if (examInfos.length === 1) {
              let examInfo = examInfos[0]
              // 判断考核结束时间是否发生改变
              if (examInfo.endTime !== $this.examInfo.endTime) {
                // 重新开始计时
                clearInterval($this.left_time_timer)
                let addMinute = Math.ceil((new Date(examInfo.endTime) - new Date($this.examInfo.endTime)) / 1000)
                msg_success("注意，本场考核已经增加了" + addMinute + "分钟延时考核时间")

                $this.$set($this.examInfo, "endTime", examInfo.endTime)
                this.secondCount()
              }
            }
          }, 2000)// 每2秒刷新一次
        },
        // 离开页面时动作
        leavePage() {
          clearInterval($this.left_time_timer)
          clearInterval($this.timing_save_timer)
          clearInterval($this.timing_update_timer)
        }
      }
    },
    // 计算每项分数和总分
    calSectionScore() {
      this.questionSections.forEach((section, sectionIndex) => {
        switch (section.sectionType) {
          case 'Radio':
            this.sectionScore[sectionIndex] = this.examResult['radioQuestionScore']
            break
          case 'Multiple':
            this.sectionScore[sectionIndex] = this.examResult['multipleQuestionScore']
            break
          case 'Judge':
            this.sectionScore[sectionIndex] = this.examResult['judgeQuestionScore']
            break
          case 'Match':
            this.sectionScore[sectionIndex] = this.examResult['matchQuestionScore']
            break
          case 'Blank':
            this.sectionScore[sectionIndex] = this.examResult['blankQuestionScore']
            break
          case 'Completion':
            this.sectionScore[sectionIndex] = this.examResult['completionQuestionScore']
            break
          case 'Essay':
            this.sectionScore[sectionIndex] = this.examResult['essayQuestionScore']
            break
          case 'Analysis':
            this.sectionScore[sectionIndex] = this.examResult['analysisQuestionScore']
            break
        }
      })
    },
    // 点击查看分析按钮
    async clickAnalysisBtn() {
      // 获取考卷试题作答分析情况
      if (!this.analysis) {
        if (this.examInfo.examStatus === 'Over') {
          let analysisList = await AnalysisModel.getSingleQuestionAnalysis({examId: this.examId})
          if (analysisList[this.examId]) {
            // 生成对象
            this.questionAnalysis = objectArrToIdObject(analysisList[this.examId], 'questionId')
            this.analysis = true
          }
        }
      } else {
        this.analysis = false
      }

    }
  }
}
</script>

<style lang="less" scoped>
// 考卷标题
.exam-title {
  .title {
    font-size: 22px;
    color: #3A71A8;
    margin-bottom: 10px;
  }

  .infos {
    margin-bottom: 10px;
    color: #444;
  }

  .left-time {
    color: orangered;
  }
}

.student-info {
  margin-bottom: 20px;
}

.box-content {
  padding: 20px 40px;
}

// 试题列表
.section-div {
  margin-bottom: 20px;

  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bolder;
  }

  .section-questions {
    .question-title {
      margin-bottom: 10px;
    }

    .options {
      .el-radio, .el-checkbox {
        margin-bottom: 5px;
      }
    }
  }

  .question-blank, .question-multiple {
    margin-bottom: 20px;
  }

  // 显示选项列表
  .options-show {
    margin-bottom: 10px;

    > div {
      font-size: 15px;
      color: #555;
      margin-top: 5px;
    }
  }
}

.answer {
  margin-top: 5px;
  margin-bottom: 8px;

  .right {
    color: green;
  }

  .wrong {
    color: orangered;
  }

  .right-answer {
    margin-left: 20px;
  }

  .refer-answer {
    margin-top: 10px;
    color: #909399;
    font-size: 13px;
  }
}

.completion_options .el-input__inner {

}

/*连线题 答案设置*/
.match-question {
  width: 400px;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;

  .left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .list {
    .li {
      text-align: center;
      border: 1px dashed #cecece;
      cursor: pointer;
      width: 150px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      border-radius: 5px;
      position: relative;
    }
  }

  .lines {
    position: relative;
  }
}

/*  连线题 考卷展示*/
.match-question-view {
  width: 400px;
  margin-left: 50px;
  margin-top: 10px;
  position: relative;

  .li {
    margin-top: 10px;
  }
}

/*  倒计时悬浮框*/
.left-time-box {
  text-align: center;
  position: fixed;
  right: 20px;
  top: 50%;
  margin: 0 auto;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 1px solid #cecece;
  color: #dd1100;
  font-size: 15px;
  font-weight: bold;
}

// 每个试题的分析
.question-analysis {
  font-size: 13px !important;
  margin-top: 5px;
  //background-color: #f4f4f5;
  padding: 2px;
  padding-left: 25px;
  border-radius: 6px;
  width: 255px;
  color: #777 !important;

  > div {
    margin-top: 5px;
  }

}

.title-score-des {
  font-weight: normal;
  font-size: 16px;
}

.title-score-own {
  font-weight: normal;
  margin-left: 5px;
  font-size: 16px;
  color: orangered;
}

.show-score {
  font-size: 15px;
  color: #008000;
}

.text-center {
  text-align: center;
}

</style>
<!--v-html渲染的试题标题内容-->
<style>
.content-vhtml p {
  margin: 0;
  margin-bottom: 5px;
}
</style>
